@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.transaction-detail-divs{
  padding: 0.8% 0%;
  display: flex;
  align-items: center;
}

.transaction-detail-divs> :nth-child(1){
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
}

.transaction-detail-divs> :nth-child(2){
  font-size: 13px;
  margin-right: 10px;
}


.copy-feedback{
  margin-left: 10px;
}

.css-1iqbypn:focus{
  box-shadow: unset !important;
}

.otp-background{
  height:100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-box{
  background-color: #f8f8f8;
  width: 500px;
  height: 400px;
  border-radius: 8px;
  border: 1px solid rgb(239, 232, 232);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.09);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.09);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.09);
}

.otp-box>:nth-child(1)>img{
  width: 130px;
  height: 130px;
}

.otp-box>:nth-child(1){
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otp-box>:nth-child(2){
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-top: 1%;
}

.otp-box>:nth-child(3){
  text-align: center;
  font-size: 13px;
  margin-top: 1%;
}

.otp-box>:nth-child(4){
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.otp-box>:nth-child(5){
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.otp-box>:nth-child(5)>button{
  width: 150px;
  height: 50px;
  font-weight: bold;
  font-size: 15px;
}

.otp-inputs{
  border: 1px solid rgb(198, 189, 189);
  width: 35px !important;
  height: 35px !important;
  border-radius: 5px;
  margin: 10px;
}

.cron-status-update{
padding: 20px;
border-radius: 15px;
}

.cron-status-update>p{
  text-align: center;
  font-weight: 700;
  font-family: DM Sans;
  font-size: 25px;
}

.cron-btn{
  display: flex;
  justify-content: center;
  margin-top: 20px;

}

.cron-btn>button{
  width:120px;
  border-radius:8px
}

.cron-input{
  height: 50px;
  border-radius: 10px;
}

.cron-input-label{
  color:black;
  margin-top: 15px;
}

.t-table-btn{
  border: none;
}

.t-table-btn:focus{
  background-color: #f4f7fe !important;
}

.t-table-btn:active{
  background-color: #f4f7fe !important;
}

.t-table-btn:hover{
  color: #a3aed0 !important;
}

.exchanges-count-div>:nth-child(1), .exchanges-count-div>:nth-child(2), .exchanges-count-div>:nth-child(3), .exchanges-count-div>:nth-child(4), .exchanges-count-div>:nth-child(5), .exchanges-count-div>:nth-child(6), .exchanges-count-div>:nth-child(7), .exchanges-count-div>:nth-child(8){
  margin-right: 15px;
  margin-bottom: 20px;
}

.coins-img{
  width: 50px;
}

.coin-link{
  background: #a58dd8;
  padding: 8px 25px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}

.coin-link:hover{
  color: white;
}

.refresh{
  background-color: #f5f7ff !important;
  color: #a3aed0;
  font-weight: 400 !important;
  border-radius: 8px !important;
}

.exchange-prof-drop-btn:focus{
  text-decoration: none;
  box-shadow:none;
}

.exchange-prof-drop-btn:hover{
  text-decoration: none;
}
